import ApiService from "@/core/services/api/v2/api.service";
import store from "@/core/services/store";

const COMPANY_SENDER_FILTER_KEY = "CompanySenderFilter";
const COMPANY_TRANSPORTER_FILTER_KEY = "CompanyTransporterFilter";
const COMPANY_MY_SENDERS_FILTER_KEY = "CompanyMySendersFilter";

const CompanyService = {
  // My own company
  getMyCompanyProfile() {
    return new Promise(resolve => {
      ApiService.get("company")
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateMyCompanyProfile(newData) {
    return new Promise(resolve => {
      ApiService.put("company", newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getMyCompanyConditions() {
    return new Promise(resolve => {
      ApiService.get("companyConditions")
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateMyCompanyConditions(newData) {
    return new Promise(resolve => {
      ApiService.put("companyConditions", newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  // Superadmin side

  getSomeCompanyProfile(companyID) {
    return new Promise(resolve => {
      ApiService.get("company/" + companyID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateSomeCompanyProfile(companyID, newData) {
    return new Promise(resolve => {
      ApiService.put("company/" + companyID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteSomeCompanyProfile(companyID) {
    return new Promise(resolve => {
      ApiService.delete("company/" + companyID)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  searchCompanies(
    companyTypeID,
    searchText,
    filterByProvinceID,
    filterBySubscriptionTypeID,
    filterBySubscriptionStatusID,
    filterByCreatedAtFrom,
    filterByCreatedAtTo,
    filterByActiveUntilFrom,
    filterByActiveUntilTo,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      const queryUrl =
        companyTypeID === store.getters.companyTypesList.ID_SENDER
          ? "sender"
          : "transporter";

      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByProvinceID = parseInt(filterByProvinceID);
      if (!isNaN(filterByProvinceID) && filterByProvinceID > 0) {
        queryParams.ProvinceID = filterByProvinceID;
      }

      filterBySubscriptionTypeID = parseInt(filterBySubscriptionTypeID);
      if (
        !isNaN(filterBySubscriptionTypeID) &&
        filterBySubscriptionTypeID > 0
      ) {
        queryParams.SubscriptionTypeID = filterBySubscriptionTypeID;
      }

      filterBySubscriptionStatusID = parseInt(filterBySubscriptionStatusID);
      if (
        !isNaN(filterBySubscriptionStatusID) &&
        filterBySubscriptionStatusID > 0
      ) {
        queryParams.SubscriptionStatusID = filterBySubscriptionStatusID;
      }

      filterByCreatedAtFrom = filterByCreatedAtFrom.trim();
      if (filterByCreatedAtFrom.length > 0) {
        queryParams.CreatedAtFrom = filterByCreatedAtFrom;
      }

      filterByCreatedAtTo = filterByCreatedAtTo.trim();
      if (filterByCreatedAtTo.length > 0) {
        queryParams.CreatedAtTo = filterByCreatedAtTo;
      }

      filterByActiveUntilFrom = filterByActiveUntilFrom.trim();
      if (filterByActiveUntilFrom.length > 0) {
        queryParams.ActiveUntilFrom = filterByActiveUntilFrom;
      }

      filterByActiveUntilTo = filterByActiveUntilTo.trim();
      if (filterByActiveUntilTo.length > 0) {
        queryParams.ActiveUntilTo = filterByActiveUntilTo;
      }

      if (companyTypeID === store.getters.companyTypesList.ID_SENDER) {
        window.sessionStorage.setItem(
          COMPANY_SENDER_FILTER_KEY,
          JSON.stringify(queryParams)
        );
      } else {
        window.sessionStorage.setItem(
          COMPANY_TRANSPORTER_FILTER_KEY,
          JSON.stringify(queryParams)
        );
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query(queryUrl, { params: queryParams })
        .then(response => {
          // Return full response, not just "Companies" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  exportCompanies(
    companyTypeID,
    searchText,
    filterByProvinceID,
    filterBySubscriptionTypeID,
    filterBySubscriptionStatusID,
    filterByCreatedAtFrom,
    filterByCreatedAtTo,
    filterByActiveUntilFrom,
    filterByActiveUntilTo,
    orderBy,
    orderDesc
  ) {
    return new Promise(resolve => {
      const downloadUrl =
        companyTypeID === store.getters.companyTypesList.ID_SENDER
          ? "sender/export"
          : "transporter/export";

      // Parse params
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByProvinceID = parseInt(filterByProvinceID);
      if (!isNaN(filterByProvinceID) && filterByProvinceID > 0) {
        queryParams.ProvinceID = filterByProvinceID;
      }

      filterBySubscriptionTypeID = parseInt(filterBySubscriptionTypeID);
      if (
        !isNaN(filterBySubscriptionTypeID) &&
        filterBySubscriptionTypeID > 0
      ) {
        queryParams.SubscriptionTypeID = filterBySubscriptionTypeID;
      }

      filterBySubscriptionStatusID = parseInt(filterBySubscriptionStatusID);
      if (
        !isNaN(filterBySubscriptionStatusID) &&
        filterBySubscriptionStatusID > 0
      ) {
        queryParams.SubscriptionStatusID = filterBySubscriptionStatusID;
      }

      filterByCreatedAtFrom = filterByCreatedAtFrom.trim();
      if (filterByCreatedAtFrom.length > 0) {
        queryParams.CreatedAtFrom = filterByCreatedAtFrom;
      }

      filterByCreatedAtTo = filterByCreatedAtTo.trim();
      if (filterByCreatedAtTo.length > 0) {
        queryParams.CreatedAtTo = filterByCreatedAtTo;
      }

      filterByActiveUntilFrom = filterByActiveUntilFrom.trim();
      if (filterByActiveUntilFrom.length > 0) {
        queryParams.ActiveUntilFrom = filterByActiveUntilFrom;
      }

      filterByActiveUntilTo = filterByActiveUntilTo.trim();
      if (filterByActiveUntilTo.length > 0) {
        queryParams.ActiveUntilTo = filterByActiveUntilTo;
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      // Do search
      ApiService.queryBlob(downloadUrl, { params: queryParams })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getCachedFilters(companyTypeID) {
    let cachedFilters =
      companyTypeID === store.getters.companyTypesList.ID_SENDER
        ? window.sessionStorage.getItem(COMPANY_SENDER_FILTER_KEY)
        : window.sessionStorage.getItem(COMPANY_TRANSPORTER_FILTER_KEY);

    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  },

  // Transporter side: my Senders

  searchMySenders(
    searchText,
    filterByProvinceID,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByProvinceID = parseInt(filterByProvinceID);
      if (!isNaN(filterByProvinceID) && filterByProvinceID > 0) {
        queryParams.ProvinceID = filterByProvinceID;
      }

      window.sessionStorage.setItem(
        COMPANY_MY_SENDERS_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("companySender", { params: queryParams })
        .then(response => {
          // Return full response, not just "Companies" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  exportMySenders(searchText, filterByProvinceID, orderBy, orderDesc) {
    return new Promise(resolve => {
      // Parse params
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByProvinceID = parseInt(filterByProvinceID);
      if (!isNaN(filterByProvinceID) && filterByProvinceID > 0) {
        queryParams.ProvinceID = filterByProvinceID;
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      // Do search
      ApiService.queryBlob("companySender/export", { params: queryParams })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getMySendersCachedFilters() {
    let cachedFilters = window.sessionStorage.getItem(
      COMPANY_MY_SENDERS_FILTER_KEY
    );

    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  },

  getMySendersProfile(senderID) {
    return new Promise(resolve => {
      ApiService.get("companySender/" + senderID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  }
};

export default CompanyService;
