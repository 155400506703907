import ApiService from "@/core/services/api/v2/api.service";

const SubscriptionTypeService = {
  listAll(filterByCompanyTypeID) {
    return new Promise(resolve => {
      // Parse optional filter
      const queryParams = {};
      filterByCompanyTypeID = parseInt(filterByCompanyTypeID);
      if (!isNaN(filterByCompanyTypeID) && filterByCompanyTypeID > 0) {
        queryParams.CompanyTypeID = filterByCompanyTypeID;
      }

      ApiService.query("subscriptionType", { params: queryParams })
        .then(response => {
          resolve(response.data.response.SubscriptionTypes);
        })
        .catch(() => {
          resolve([]);
        });
    });
  },

  getSenderBasic() {
    return new Promise(resolve => {
      ApiService.get("subscriptionSenderBasic")
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getProfile(subscriptionTypeID) {
    return new Promise(resolve => {
      ApiService.get("subscriptionType/" + subscriptionTypeID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateProfile(subscriptionTypeID, newData) {
    return new Promise(resolve => {
      ApiService.put("subscriptionType/" + subscriptionTypeID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  }
};

export default SubscriptionTypeService;
