import ApiService from "@/core/services/api/v2/api.service";

const SubscriptionService = {
  // Admin side
  getSubscriptionProfileMyCompany() {
    return new Promise(resolve => {
      ApiService.get("companySubscription")
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateSubscriptionProfileMyCompany(newData) {
    return new Promise(resolve => {
      ApiService.put("companySubscription", newData)
        .then(response => {
          // Return full response data, because it may hold payment info
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  cancelSubscriptionProfileMyCompany() {
    return new Promise(resolve => {
      ApiService.delete("companySubscription")
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  // Superadmin side
  getSubscriptionProfileByCompany(companyID) {
    return new Promise(resolve => {
      ApiService.get("companySubscription/" + companyID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateSubscriptionProfileByCompany(companyID, newData) {
    return new Promise(resolve => {
      ApiService.put("companySubscription/" + companyID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  cancelSubscriptionProfileByCompany(companyID) {
    return new Promise(resolve => {
      ApiService.delete("companySubscription/" + companyID)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  }
};

export default SubscriptionService;
