import ApiService from "@/core/services/api/v2/api.service";

const COMPANY_CLASS_LIST_KEY = "CompanyClassList";

const CompanyClassService = {
  listAll() {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(COMPANY_CLASS_LIST_KEY);
      if (cachedList) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.get("companyClass")
          .then(response => {
            let newList = response.data.response.CompanyClasses;

            window.sessionStorage.setItem(
              COMPANY_CLASS_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  cleanCachedList() {
    window.sessionStorage.removeItem(COMPANY_CLASS_LIST_KEY);
  }
};

export default CompanyClassService;
